// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { navigate, PageProps } from "gatsby"

import { useEffect } from "react"

const NotFound: React.FC<PageProps> = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return null
}

export default NotFound